import React, { useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, StringParam } from "use-query-params";
import { Loading } from "@components/Loading/Loading.component";
import { resetPasswordAsync } from "../../state/auth/auth.actions";
import { authLoadingSelector } from "../../state/auth/auth.selectors";
import { authSdk } from "../../utils/auth";
import { authBgColor } from "../Login/components/consts";
import { logException } from "@helpers/logException/logException";
import {
  LayoutShell,
  PromoTwo,
  PromoTwoBody,
  PasswordReset,
} from "@ifgengineering/client-auth-components";
import RegulatoryBanner from "@components/RegulatoryBanner/RegulatoryBanner";
import { PublicRouteProps } from "../../pages/app";
import Cur8StyleOtpForm from "../otp/Cur8StyleOtpForm";

const ResetPassword: FC<PublicRouteProps> = () => {
  const dispatch = useDispatch();
  const [email] = useQueryParam("email", StringParam);
  const [token] = useQueryParam("token", StringParam);
  const [is2fa] = useQueryParam("is2fa", StringParam);
  const is2faBool = is2fa === "true";
  const [pageState, setPageState] = useState({
    showOtpForm: false,
    otp: "",
    newPassword: "",
  });
  const isLoading = useSelector(authLoadingSelector);
  const resetPassword = ({
    email,
    newPassword,
    token,
    otp,
  }: {
    email: string;
    newPassword: string;
    token: string;
    otp?: string;
  }) => dispatch(resetPasswordAsync({ email, newPassword, token, otp }));

  const resendOtp = () => {
    try {
      authSdk.generateOtp(email as string);
    } catch (e) {
      logException({
        scopeEmail: email as string,
        tag: "generateOtp",
        exception: e,
      });
    }
  };

  const handleOtpSubmit = async ({
    otp,
  }: {
    otp: string;
    setStatus: (status?: any) => void;
  }): Promise<void> => {
    resetPassword({
      email: email as string,
      newPassword: pageState.newPassword,
      token: token as string,
      otp,
    });
  };

  const handleOtpCancel = () => {
    setPageState({
      ...pageState,
      showOtpForm: false,
    });
  };

  const handleSubmit = async (values: { newPassword: string }) => {
    if (is2faBool) {
      setPageState({
        ...pageState,
        newPassword: values.newPassword,
        showOtpForm: true,
      });
    } else {
      resetPassword({
        email: email as string,
        newPassword: values.newPassword,
        token: token as string,
      });
    }
  };

  if (isLoading) return <Loading />;

  return (
    <LayoutShell
      Banner={() => <RegulatoryBanner />}
      Promo={() => <PromoTwo />}
      MobileFooter={() => <PromoTwoBody />}
      showHeader={true}
      showTrustBox={true}
      backgroundColor={authBgColor}
      isLayoutBEnabled={false}
    >
      {pageState.showOtpForm ? (
        <Cur8StyleOtpForm
          resendOtp={resendOtp}
          handleSubmit={handleOtpSubmit}
          handleCancel={handleOtpCancel}
          userEmail={email || ""}
          submitButtonText="Submit"
        />
      ) : (
        <PasswordReset
          onSubmit={(password) => {
            resendOtp();
            handleSubmit({
              newPassword: password,
            });
          }}
        />
      )}
    </LayoutShell>
  );
};

export default ResetPassword;

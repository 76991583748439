import React from "react";
import Icon from "@icon-park/react/es/all";
import { theme } from "@styles/theme";
import { IFlowNavIcons } from "./types";

export const FlowNavIcons = ({
  isCompleted,
  isSelected,
  selectStateIcon = {
    type: "RightSmall",
    color: "GREEN400",
  },
  completeStateIcon = {
    type: "CheckSmall",
    color: "SUCCESS500",
  },
}: IFlowNavIcons): JSX.Element => {
  if (!isSelected && !isCompleted) {
    return <></>;
  }

  const type = isSelected ? selectStateIcon.type : completeStateIcon.type;
  const color = isSelected
    ? theme.colors[selectStateIcon.color]
    : theme.colors[completeStateIcon.color];

  return (
    <>
      <Icon
        type={type}
        fill={color}
        size={24}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
    </>
  );
};

import { Portfolio } from "@ifgengineering/client-invest-sdk";
import { RootState } from "../rootReducer";

export const portfolioSelector = (state: RootState): Portfolio[] =>
  state.portfolio.data || [];

export const portfolioItemSelector = (
  state: RootState,
  commitmentId: string
): Portfolio | undefined =>
  state.portfolio.data.find(
    (portfolio) => portfolio.commitment.id === commitmentId
  );

export const neverLoadedPortfolioSelector = (state: RootState): boolean =>
  state.portfolio.neverLoaded;

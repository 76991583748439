import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAnalytics from "@helpers/useAnalytics";
import {
  getUserInfoAsync,
  signUpAsync,
  signInGoogleAsync,
  signInAppleAsync,
} from "../../state/auth/auth.actions";
import { navigate } from "@reach/router";
import { authBgColor } from "../Login/components/consts";
import {
  LayoutShell,
  PromoTwo,
  PromoTwoBody,
  Signup,
  useClientAuthComponents,
} from "@ifgengineering/client-auth-components";
import RegulatoryBanner from "@components/RegulatoryBanner/RegulatoryBanner";
import { useGoogleLogin } from "@react-oauth/google";
import { APPLE_CLIENT_ID } from "gatsby-env-variables";
import { AppleAuthResponseData } from "@ifgengineering/client-auth-components/src/components/Widget/pages/Signin/types";
import { authSelector } from "@state/auth/auth.selectors";
import {
  investorSelector,
  neverLoadedInvestorSelector,
} from "@state/investor/investor.selectors";
import { PublicRouteProps } from "../../pages/app";
import getAndStoreAppleIdToken from "@helpers/getAndStoreAppleIdToken";

const SignupForm: FC<PublicRouteProps> = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { isAuthenticated } = useSelector(authSelector);
  const investor = useSelector(investorSelector);
  const isNeverLoadedInvestor = useSelector(neverLoadedInvestorSelector);

  const { setAppleAuthData } = useClientAuthComponents();

  const hasCompletedOnboarding = investor?.onboardingStatus === "COMPLETED";

  useEffect(() => {
    if (!isAuthenticated || isNeverLoadedInvestor) {
      return;
    }

    const shouldRedirectToOnboarding =
      isAuthenticated && !hasCompletedOnboarding;

    if (shouldRedirectToOnboarding) {
      navigate("/app/onboarding");
      return;
    }

    navigate("/app");
  }, [isAuthenticated, isNeverLoadedInvestor]);

  const signUpUser = ({
    email,
    password,
    captchaToken,
  }: {
    email: string;
    password: string;
    captchaToken: string;
  }) => dispatch(signUpAsync({ email, password, captchaToken }));

  const fireSignupAttempted = useAnalytics("signupAttempt");

  const handleSignUpSubmit = async (values: {
    email: string;
    password: string;
    captchaToken: string;
  }) => {
    (await fireSignupAttempted)({ email: values.email, method: "password" });

    const redirectOnSuccess = () => navigate("/app/onboarding");

    try {
      const response = await signUpUser(values);

      if (response?.payload?.response?.status === 409) {
        setError("Email already exists");
      }

      if (response?.type === signUpAsync.fulfilled.type) {
        await dispatch(getUserInfoAsync());
        redirectOnSuccess();
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/userinfo.email",
    ux_mode: "popup",
    onSuccess: async (codeRes) =>
      dispatch(signInGoogleAsync({ authorizationCode: codeRes.code })),
    onError: (errorResponse: any) => console.log(errorResponse),
  });

  const handleAppleLogin = async (data: AppleAuthResponseData) => {
    const { id_token, code } = data.authorization;
    const { token: appleIdToken } = getAndStoreAppleIdToken(id_token);
    const isTokenAndCodePresent = appleIdToken && code;

    if (isTokenAndCodePresent) {
      setAppleAuthData({ authorizationCode: code });

      try {
        const res = await dispatch(
          signInAppleAsync({
            authorizationCode: code,
            appleIdToken: appleIdToken,
          })
        ).unwrap();
        const isPrivateEmail = res?.isPrivateRelayEmail;
        if (isPrivateEmail) {
          setAppleAuthData({ isPrivateEmail });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const APPLE_REDIRECT_URI = location.origin;

  return (
    <LayoutShell
      Banner={() => <RegulatoryBanner />}
      Promo={() => <PromoTwo />}
      MobileFooter={() => <PromoTwoBody />}
      showHeader={true}
      showTrustBox={true}
      backgroundColor={authBgColor}
      isLayoutBEnabled={false}
    >
      <Signup
        onSubmit={handleSignUpSubmit}
        title={"Sign Up"}
        loginUrl="/app/login"
        errorMessage={error}
        googleLogin={{
          enable: true,
          onClick: handleGoogleLogin,
        }}
        appleLogin={{
          enable: true,
          clientId: APPLE_CLIENT_ID,
          redirectURI: APPLE_REDIRECT_URI,
          onClick: handleAppleLogin,
        }}
        signupRedirect="/app/onboarding"
      />
    </LayoutShell>
  );
};

export default SignupForm;

import React, { useEffect, useState } from "react";
import { Text } from "@ifgengineering/component-library";
import Accordion from "@components/Accordion/Accordion.component";
import { IFlowNav, IFlowNavColors, INavItem } from "./types";
import {
  FlowNavContainer,
  FlowNavItemContainer,
  FlowSubNavContainer,
} from "./styles";
import { FlowNavIcons } from "./FlowNavIcons";

const defaultColors: IFlowNavColors = {
  background: "SLATE900",
  navItem: "SLATE400",
  selectedNavItem: "SLATE100",
};

const FlowNav = ({
  data,
  onSelect,
  selectedItemId,
  selectStateIcon = {
    type: "RightSmall",
    color: "GREEN400",
  },
  completeStateIcon = {
    type: "CheckSmall",
    color: "SUCCESS500",
  },
  disableForwardClick,
  completedSections = [],
  colors = defaultColors,
  disabled = false,
}: IFlowNav): JSX.Element => {
  const defaultNavItem = data[0]?.subItems
    ? { id: data[0].id, subItem: { id: data[0].subItems[0].id } }
    : { id: data[0].id };
  const defaultOpenedNavItem = data[0]?.subItems ? data[0].id : "";
  const [openedNavItem, setOpenedNavItem] =
    useState<string>(defaultOpenedNavItem);
  const [selectedNavItem, setSelectedNavItem] = useState<{
    id: string;
    subItem?: { id: string | undefined };
  }>(defaultNavItem);

  const getNavItemFromPosition = (main: number, sub: number) => ({
    id: data[main]?.id,
    subItem: { id: data[main]?.subItems?.[sub]?.id },
  });

  useEffect(() => {
    if (selectedItemId) {
      const [itemPosition, subItemPosition] = selectedItemId?.split("-");
      const navItem = getNavItemFromPosition(
        Number(itemPosition),
        Number(subItemPosition) || 0
      );
      if (navItem?.subItem) setOpenedNavItem(navItem.id);
      if (!navItem?.subItem.id) setOpenedNavItem("");
      setSelectedNavItem(navItem);
    }
  }, [selectedItemId]);

  const handleClick = (item: INavItem) => {
    if (disabled) {
      return;
    }

    const isComplete = completedSections.includes(item.id.toString());
    const nextSectionId =
      Number(completedSections[completedSections.length - 1]) + 1;
    const isNextSectionValid = nextSectionId === Number(item?.id);
    const isForwardNavItem = Number(selectedNavItem?.id) < Number(item.id);

    if (
      !isComplete &&
      !isNextSectionValid &&
      disableForwardClick &&
      isForwardNavItem
    ) {
      return;
    }

    const isSelectedNavItem = item?.id === selectedNavItem?.id;
    const isOpenAccordion = openedNavItem === item.id;
    if (onSelect) !isSelectedNavItem && onSelect({ item });
    setSelectedNavItem({
      id: item.id,
      subItem: selectedNavItem.subItem
        ? { ...selectedNavItem.subItem }
        : undefined,
    });
    isOpenAccordion ? setOpenedNavItem("") : setOpenedNavItem(item.id);
  };

  const handleSubItemClick = (item: INavItem, subItem: INavItem) => {
    const isComplete = completedSections.includes(item.id.toString());
    const currentSubId = Number(selectedNavItem.subItem?.id?.split("-")[1]);
    const selectedSubId = Number(subItem?.id.split("-")[1]);
    if (!isComplete && disableForwardClick && selectedSubId > currentSubId)
      return;

    const isSelectedNavSubItem = selectedNavItem?.subItem?.id === subItem?.id;
    setSelectedNavItem({ ...selectedNavItem, subItem: { id: subItem?.id } });
    if (onSelect) !isSelectedNavSubItem && onSelect({ item, subItem });
  };

  return (
    <FlowNavContainer background={colors.background}>
      {data.map((item) => {
        const hasSubItems = !!item.subItems?.length;
        const isSelectedNavItem = item.id === selectedNavItem?.id;
        const isOpenAccordion = openedNavItem === item.id;
        return (
          <FlowNavItemContainer key={item.id}>
            <FlowNavIcons
              isSelected={isSelectedNavItem}
              isCompleted={completedSections.includes(item.id.toString())}
              selectStateIcon={selectStateIcon}
              completeStateIcon={completeStateIcon}
            />
            {!hasSubItems ? (
              <Text
                key={item.id}
                style={{ cursor: "pointer" }}
                type="S18"
                color={
                  isSelectedNavItem ? colors.selectedNavItem : colors.navItem
                }
                onClick={() => handleClick(item)}
              >
                {item.title}
              </Text>
            ) : (
              <Accordion
                onClick={() => handleClick(item)}
                isOpen={isOpenAccordion}
                title={item.title}
                key={item.id}
                titleFontSize="S18"
                titleColor={
                  isSelectedNavItem ? colors.selectedNavItem : colors.navItem
                }
              >
                <FlowSubNavContainer>
                  {item.subItems?.map((subItem) => {
                    const isSelectedNavSubItem =
                      selectedNavItem?.subItem?.id === subItem.id;

                    return (
                      <Text
                        key={subItem.id}
                        type="P16"
                        style={{ cursor: "pointer" }}
                        color={
                          isSelectedNavSubItem
                            ? colors.selectedNavItem
                            : colors.navItem
                        }
                        onClick={() => handleSubItemClick(item, subItem)}
                      >
                        {subItem.title}
                      </Text>
                    );
                  })}
                </FlowSubNavContainer>
              </Accordion>
            )}
          </FlowNavItemContainer>
        );
      })}
    </FlowNavContainer>
  );
};

export default FlowNav;

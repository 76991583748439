import React from "react";

export type NavItemComponentType = {
  nextPage: () => void;
  prevPage: () => void;
  handlePageSubmit: (values: any, cb: () => void) => Promise<void>;
  isNavigatingBack?: boolean;
  standalone?: boolean;
  contextActions: any;
};

export type NavigationShellProps = {
  schema: {
    title: string;
    component?: (props: NavItemComponentType) => JSX.Element;
    subItems?: {
      title: string;
      component: (props: NavItemComponentType) => JSX.Element;
    }[];
  }[];
  disclaimer: string;
  handlePageSubmit: (values: any, cb: () => void) => Promise<void>;
  contextActions: any;
  handleSkipOnboarding: () => void;
};

export const schema: NavigationShellProps["schema"] = [
  {
    title: "Eligibility",
    component: () => <div></div>,
  },
  {
    title: "Basic Info",
    component: () => <div></div>,
  },
  {
    title: "Quiz",
    component: () => <div></div>,
  },
];

export const identityVerificationSchema: NavigationShellProps["schema"] = [
  {
    title: "Identity Verification",
    component: () => <div></div>,
  },
];

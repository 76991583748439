import { AuthUser, UserInfoOutput } from "@ifgengineering/client-auth-sdk";
import { AxiosPromise } from "axios";
import check2FA from "./check2FA";
import checkSessionStatus from "./checkSessionStatus";
import { SESSION_STATUSES } from "./types";

type checkSessionOutput = {
  status: SESSION_STATUSES;
  data?: AuthUser;
};

export const checkSession = async (
  refreshAccessToken: () => Promise<AxiosPromise>,
  userInfo: () => Promise<UserInfoOutput>
): Promise<checkSessionOutput> => {
  const { status, error, data } = await checkSessionStatus(userInfo);
  const result: checkSessionOutput = { status: SESSION_STATUSES.INVALID };

  if (error) {
    result.status = await check2FA(refreshAccessToken, error);
  }

  if (status === null) {
    return result;
  }

  if (data) {
    result.data = data;
  }

  result.status = status;

  return result;
};

import styled from "styled-components";
import { MEDIA_QUERIES, theme } from "@ifgengineering/component-library";

export const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100vh;
  width: 100%;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  z-index: 10;
  height: 100%;
  background-color: ${theme.colors["SLATE900"]};
`;

export const LeftContainerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 48px;
  height: 100%;
  width: 100%;
  max-width: 327px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.colors["SLATE200"]};
  flex-grow: 1;
`;

export const RightContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  @media ${MEDIA_QUERIES.TAB_LAND} {
    padding: 67px;
  }
`;

export const Image = styled.img<{ height?: number }>`
  height: ${({ height }) => (height ? height + "px" : "34px")};
  object-fit: contain;
  width: fit-content;
`;

export const DisclaimerContainer = styled.div`
  width: 100%;
  padding: 24px 104px;
  background-color: ${theme.colors["SLATE100"]};
`;

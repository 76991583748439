import axios from "axios";
import { CUR8_SERVER } from "gatsby-env-variables";
export interface YotiInfo {
  sessionId: string;
  sessionToken: string;
  url: string;
}
// TODO: move this to the client-invest-sdk
const createYotiSession = async (): Promise<YotiInfo | void> => {
  return await axios
    .get(`${CUR8_SERVER}/yoti`, {
      withCredentials: true,
    })
    .then((result) => result.data);
};

export default createYotiSession;

import React, { FC, useEffect, Suspense } from "react";
import styled from "styled-components";
import Loading from "@components/Loading";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector } from "@state/auth/auth.selectors";

const PrivateRouteWrapper = styled.div`
  height: 100%;
  & > span {
    height: 100%;
  }
`;

export type PrivateRouteProps = {
  component?: React.FC<{ path?: string }>;
  [x: string]: unknown;
};

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  children,
  ...rest
}) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.removeItem("intended_visit_link");
    }
  }, []);

  const authRoutes = [
    "/mfa",
    "/app/mfa",
    "/app/login",
    "/app/signup",
    "/app/signup-apple",
    "/app/verify-email",
    "/app/reset-password",
    "/app/forgot-password",
  ];
  const intendedVisitLink = location.href.split(location.host)[1];

  const isAuthRoute = authRoutes.some((route) =>
    intendedVisitLink.includes(route)
  );

  if (!isAuthRoute) {
    localStorage.setItem("intended_visit_link", intendedVisitLink);
  }
  return (
    <PrivateRouteWrapper>
      <Suspense fallback={<Loading />}>
        {Component && <Component {...rest}>{children}</Component>}
      </Suspense>
    </PrivateRouteWrapper>
  );
};

export default PrivateRoute;

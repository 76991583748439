import React, { FC, useEffect } from "react";
import { Text } from "@ifgengineering/component-library";
import PoweredByIFGLogo from "@assets/images/powered-by-ifg.png";
import ProgressBar from "@components/ProgressBar";
import {
  Container,
  ContentWrapper,
  DisclaimerContainer,
  FlowContainer,
  FormsContentContainer,
  LeftContainer,
  LeftContainerContentWrapper,
  PoweredByLogo,
  ProgressContainer,
  Separator,
} from "../styled";
import {
  IFlowNavColors,
  IFlowNavMobileColors,
  INavItem,
} from "@components/FlowNav/types";
import { navigate } from "gatsby";
import FlowNav from "@components/FlowNav/FlowNav.component";
import { transformMenuSchema } from "../../NavigationShell/helpers/transformMenuSchema";
import { schema, identityVerificationSchema } from "./schema";
import { SkipOnboardingBtn } from "../../onboarding/NavigationShell/components";
import { useSelector } from "react-redux";
import { hasKYCStartedSelector } from "../../../state/investor/investor.selectors";

export const flowNavColors: IFlowNavColors = {
  background: "SLATE900",
  navItem: "WHITE",
  selectedNavItem: "GREEN400",
};

export const flowNavMobileColors: IFlowNavMobileColors = {
  mobileBorder: "SLATE700",
  mobileBackground: "SLATE900",
  mobileText: "SLATE400",
  selectedMobileBorder: "GREEN400",
  selectedMobileBackground: "SLATE900",
  selectedMobileText: "GREEN400",
};

const Layout: FC = ({ children }) => {
  const kycStarted = useSelector(hasKYCStartedSelector);
  const menuData = transformMenuSchema(schema) as Array<INavItem>;
  const identityVerificationData = transformMenuSchema(
    identityVerificationSchema
  ) as Array<INavItem>;
  const handleSkipClick = () => {
    navigate("/app/deals");
  };

  useEffect(() => {
    if (kycStarted) {
      navigate("/app/deals");
    }
  }, [kycStarted]);

  return (
    <Container>
      <LeftContainer>
        <LeftContainerContentWrapper>
          <ProgressContainer>
            <Text type="U14" color="SLATE300">
              Progress
            </Text>
            <ProgressBar
              barColor="BLUE600"
              barHeight={8}
              barBgColor="WHITE"
              percentage={100}
            />
          </ProgressContainer>

          <FlowContainer>
            <FlowNav
              disabled
              colors={flowNavColors}
              mobileColors={flowNavMobileColors}
              mobileNavPadding="0 24px"
              disableForwardClick={true}
              data={menuData}
              onSelect={() => true}
              selectedItemId={"A"}
              completedSections={[]}
            />
            <Separator />
            <FlowNav
              disabled
              colors={flowNavColors}
              mobileColors={flowNavMobileColors}
              mobileNavPadding="0 24px"
              disableForwardClick={true}
              data={identityVerificationData}
              onSelect={() => true}
              selectedItemId={""}
              completedSections={[]}
            />
          </FlowContainer>
          <SkipOnboardingBtn onClick={handleSkipClick} />
          <PoweredByLogo height={33} src={PoweredByIFGLogo} />
        </LeftContainerContentWrapper>
      </LeftContainer>
      <ContentWrapper>
        <FormsContentContainer>{children}</FormsContentContainer>
        <DisclaimerContainer>
          <Text type="P12" color="SLATE700">
            Cur8 Capital is a trading name of IFG.VC Limited, which is a
            subsidiary of Islamicfinanceguru Limited. IFG.VC Limited is
            authorised and regulated by the FCA (No. 943736). Nothing on this
            page should be construed as financial or tax advice. Remember, with
            investments your capital is at risk. Past performance is not
            necessarily an accurate indicator of future returns. © IFG.VC
            Limited. All rights reserved
          </Text>
        </DisclaimerContainer>
      </ContentWrapper>
    </Container>
  );
};

export default Layout;

import { InlineLoader, Text, theme } from "@ifgengineering/component-library";
import React, { FC, useEffect, useState } from "react";
import {
  DropArea,
  ErrorContainer,
  FormField,
  UploadDocumentsContainer,
  UploadedFile,
  UploadedFiles,
} from "../EntityForms/forms/VerifyEntity/styled";
import Icon from "@icon-park/react/es/all";
import {
  EntityDocumentOptions,
  Upload,
} from "@ifgengineering/client-invest-sdk";
import { FooterContainer } from "./styled";

const supportedFileExtensions = ["pdf", "jpg", "jpeg", "png"] as const;
export type fileExtensionType = (typeof supportedFileExtensions)[number];

interface UploadDocumentsProps {
  title: string;
  subTitle: string | React.ReactElement;
  documents: Upload[];
  footerNote?: string | React.ReactElement;
  documentType?: EntityDocumentOptions;
  supportedFiles?: fileExtensionType[];
  isSingleFileUpload?: boolean;
  onUpload: (file: File, documentType?: EntityDocumentOptions) => void;
  onDelete: (
    documentId: string,
    documentType?: EntityDocumentOptions
  ) => Promise<void>;
}

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000;

const UploadDocuments: FC<UploadDocumentsProps> = ({
  title,
  subTitle,
  footerNote,
  documentType,
  documents,
  isSingleFileUpload,
  supportedFiles,
  onUpload,
  onDelete,
}) => {
  const [files, setFiles] = useState<Record<string, File>>({});
  const [deletingFile, setDeletingFile] = useState<Record<string, boolean>>({});
  const [processing, setProcessing] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>(
    {}
  );

  async function processObject(files: Record<string, File>): Promise<void> {
    setProcessing(true);

    for (const fileName of Object.keys(files)) {
      await onUpload(files[fileName], documentType);
      delete files[fileName];
      setFiles(files);
    }

    setProcessing(false);
  }

  const fileExtensions = supportedFiles ?? supportedFileExtensions;

  const dottedExtensionString = fileExtensions
    .map((ext) => `.${ext}`)
    .join(", ");
  const acceptedFileTypeNote = `Accepted file types: ${dottedExtensionString}.`;
  const maximumSizeNote = "Maximum size: 5 MB";
  const acceptanceNote = `${acceptedFileTypeNote} ${maximumSizeNote}`;

  const getErrorMessage = (file: File) => {
    if (file.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
      return `${file.name}: Is bigger than 5 MB`;
    }

    const result = file.name.match(/\.([0-9a-z]+)(?:[?#]|$)/i);

    if (!result) {
      return false;
    }

    const [_, extension] = result as Array<any>;

    if (!fileExtensions.includes(extension?.toLowerCase())) {
      return `${file.name}: Unsupported file format`;
    }

    return false;
  };

  useEffect(() => {
    if (!processing) {
      processObject(files);
    }
  }, [files, processing]);

  const addNewFiles = (newFiles: FileList) => {
    for (const file of newFiles) {
      const errorMessage = getErrorMessage(file);

      if (errorMessage) {
        setErrorMessages({
          ...errorMessages,
          [file.name]: errorMessage,
        });
      } else {
        files[file.name] = file;
      }
    }

    return { ...files };
  };

  const handleNewFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: newFiles } = e.target;
    if (newFiles?.length) {
      setErrorMessages({});
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
    }
  };

  const removeFile = async (id: string) => {
    setErrorMessages({});
    if (!deletingFile[id]) {
      setDeletingFile({
        ...deletingFile,
        [id]: true,
      });
      await onDelete(id, documentType);
      setDeletingFile({
        ...deletingFile,
        [id]: false,
      });
    }
  };

  const getFooterNote = (footerNote: string | React.ReactElement) => {
    if (typeof footerNote === "string") {
      return (
        <Text type="P14" color="SLATE600">
          {footerNote}
        </Text>
      );
    }
    return footerNote;
  };

  return (
    <UploadDocumentsContainer>
      {title && (
        <Text type="S16" color="SLATE800">
          {title}
        </Text>
      )}
      {subTitle && (
        <Text type="P14" color="SLATE600">
          {subTitle}
        </Text>
      )}
      <DropArea>
        {processing ? (
          <InlineLoader size={30} thickness={2} />
        ) : (
          <>
            <Icon type="Upload" fill={theme.colors.SLATE700} size="15" />
            <Text type="S14" color="BLUE600">
              Choose files
            </Text>
            <Text type="S14" color="SLATE800">
              or Drag documents here
            </Text>
            <FormField
              type="file"
              onChange={handleNewFileUpload}
              multiple={!isSingleFileUpload}
              title=""
              value=""
            />
          </>
        )}
      </DropArea>
      <UploadedFiles>
        {documents.map(({ id, name }) => (
          <UploadedFile key={id}>
            <Text type="P16" color="BLUE600">
              {name}
            </Text>
            <Icon
              type={deletingFile[id] ? "LoadingOne" : "CloseOne"}
              fill={
                deletingFile[id] ? theme.colors.SLATE200 : theme.colors.ERROR800
              }
              onClick={async () => {
                await removeFile(id);
              }}
            />
          </UploadedFile>
        ))}
      </UploadedFiles>
      {!!Object.keys(errorMessages).length && (
        <ErrorContainer>
          {Object.keys(errorMessages).map((fileName) => (
            <div key={fileName}>
              <Text type="P14" color="ERROR800">
                {errorMessages[fileName]}
              </Text>
            </div>
          ))}
        </ErrorContainer>
      )}
      <FooterContainer>
        {footerNote && getFooterNote(footerNote)}
        <Text type="P14" color="SLATE600">
          {acceptanceNote}
        </Text>
      </FooterContainer>
    </UploadDocumentsContainer>
  );
};

export default UploadDocuments;

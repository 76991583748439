import React, { FC, useEffect } from "react";
import Layout from "./Layout";
import {
  Card,
  MEDIA_QUERIES,
  OutlinedButton,
  Text,
  Stack,
  FilledButton,
  theme,
} from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import { navigate } from "gatsby";
import Icon from "@icon-park/react/es/all";
import useAnalytics from "@helpers/useAnalytics";

const EndVerification: FC = () => {
  const isTabPort = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const handleBackClick = () => {
    navigate(-1);
  };
  const handleVewLiveDealsClick = () => {
    navigate("/app/deals");
  };

  const firePageViewAnalytics = useAnalytics("pageView");

  const firePageView = async (eventName: string) => {
    (await firePageViewAnalytics)({ eventName });
  };

  useEffect(() => {
    firePageView("cur8:identityCompleted_pageViewed");
  }, []);

  return (
    <Layout>
      <Stack gap={95} orientation="horizontal" alignItems="flex-start">
        <Stack
          gap={16}
          orientation="vertical"
          {...(isTabPort ? {} : { minWidth: 500 })}
        >
          <Text type="H24" color="SLATE900" fontFamily="archiabold">
            Identity
          </Text>
          <Card elevation={0} width={isTabPort ? "100%" : "500px"}>
            <Stack gap={8} orientation="vertical">
              <Icon type="FileSuccess" fill={theme.colors.GREEN600} size="29" />
              <Text type="H14" color="SLATE800">
                Next steps
              </Text>
              <Text type="P14" color="SLATE800">
                If you have uploaded your documents we will let you know the
                outcome by email and notification. This usually takes a few
                minutes, but can take up to 48 hours. Please do not submit your
                documents twice.
              </Text>
            </Stack>
          </Card>
          <Stack gap={16}>
            <OutlinedButton
              width={isTabPort ? "100%" : "fit-content"}
              text="Back"
              onClick={handleBackClick}
            />
            <FilledButton
              width={isTabPort ? "100%" : "fit-content"}
              color="blue"
              text="View Live Deals"
              onClick={handleVewLiveDealsClick}
            />
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default EndVerification;

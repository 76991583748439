import React, { FC } from "react";
import Layout from "../Layout";
import {
  Card,
  MEDIA_QUERIES,
  OutlinedButton,
  Text,
  theme,
  Stack,
  FilledButton,
} from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import Icon from "@icon-park/react/es/all";
import { navigate } from "gatsby";
import styled from "styled-components";

const PageWrap = styled.div`
  max-width: 700px;
`;

const ExtraDocsFlow: FC = () => {
  const isTabPort = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const handleSkipClick = () => {
    navigate("/app/deals");
  };
  const handleVerifyNowClick = () => {
    navigate("/app/identity/extra-documents-required/start");
  };

  return (
    <Layout>
      <PageWrap>
        <Stack
          gap={16}
          orientation="vertical"
          {...(isTabPort ? {} : { width: 700 })}
        >
          <Text type="H24" color="SLATE900" fontFamily="archiabold">
            Congratulations, you are now a member and can view and invest to our
            funds and deals.
          </Text>
          <Text type="S16" color="SLATE800">
            Next step is identity verification which requires you to prove your
            identity & address. This will require following documents:
          </Text>
          <Card elevation={0}>
            <Stack gap={16} orientation="vertical">
              <Stack gap={5} alignItems="center">
                <Icon
                  type="FileSuccess"
                  fill={theme.colors.BLUE700}
                  size={24}
                />
                <Text type="S16" color="SLATE800">
                  Source of Funds
                </Text>
                <Text type="P16" color="SLATE800">
                  (e.g. Inheritance Document or Wage Slip)
                </Text>
              </Stack>
              <Stack gap={5} alignItems="center">
                <Icon
                  type="FileSuccess"
                  fill={theme.colors.BLUE700}
                  size={24}
                />
                <Text type="S16" color="SLATE800">
                  Proof of Address
                </Text>
                <Text type="P16" color="SLATE800">
                  (e.g. Utility Bill)
                </Text>
              </Stack>
              <Stack gap={5} alignItems="center">
                <Icon
                  type="FileSuccess"
                  fill={theme.colors.BLUE700}
                  size={24}
                />
                <Text type="S16" color="SLATE800">
                  Proof of Identity
                </Text>
                <Text type="P16" color="SLATE800">
                  (e.g. ID or Passport)
                </Text>
              </Stack>
            </Stack>
          </Card>
          <Text type="S16" color="SLATE800">
            You can choose to do Identity verification now or later when you
            have time and access to verification documents but keep in mind that
            you’ll need to do verification before you can do bank transfers.
          </Text>
          <Stack gap={16}>
            <OutlinedButton
              width={isTabPort ? "100%" : "fit-content"}
              text="I’ll Verify Later"
              onClick={handleSkipClick}
            />
            <FilledButton
              width={isTabPort ? "100%" : "fit-content"}
              color="blue"
              text="Verify Identity Now"
              onClick={handleVerifyNowClick}
            />
          </Stack>
        </Stack>
      </PageWrap>
    </Layout>
  );
};

export default ExtraDocsFlow;

import React from "react";
import styled from "styled-components";
import { ColorType, theme } from "@ifgengineering/component-library";

export interface ProgressBarProps {
  percentage?: number;
  barColor?: ColorType;
  barHeight?: number;
  barBgColor?: ColorType;
}

const BarContainer = styled.div<{ bgColor?: ColorType }>`
  background-color: ${({ bgColor }) => bgColor && theme.colors[bgColor]};
  border-radius: 30px;
  width: 100%;
  overflow: hidden;
`;

const Bar = styled.div<ProgressBarProps>`
  border-radius: 30px;
  width: ${(props) => props.percentage + "%"};
  height: ${(props) => props.barHeight + "px"};
  background-color: ${({ barColor }) => barColor && theme.colors[barColor]};
`;

const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  barHeight = 8,
  barColor = "BLUE600",
  barBgColor = "PEBBLE200",
}) => {
  return (
    <BarContainer bgColor={barBgColor}>
      <Bar
        data-testid="progress-bar"
        barColor={barColor}
        barHeight={barHeight}
        percentage={percentage}
      />
    </BarContainer>
  );
};

export default ProgressBar;

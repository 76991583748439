import React, { useRef } from "react";
import {
  AccordionButton,
  Container,
  Content,
  StyledArrow,
  TitleContainer,
} from "./styled";
import {
  Text,
  TextTypeAndSize,
  MEDIA_QUERIES,
} from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import { ColorType, theme } from "@styles/theme";
import Icon, { IconType, Theme } from "@icon-park/react/es/all";

const Accordion: React.FC<{
  title: string;
  titleColor?: ColorType;
  titleFontSize?: TextTypeAndSize;
  titleIcon?: {
    type: IconType;
    fill?: ColorType;
    size?: number;
    theme?: Theme;
  };
  isOpen?: boolean;
  bgColor?: string | ColorType;
  padding?: number;
  borderRadius?: number;
  onClick: () => void;
}> = ({
  title,
  titleColor,
  titleFontSize,
  titleIcon,
  isOpen,
  bgColor,
  padding,
  borderRadius,
  onClick,
  children,
}) => {
  const content = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const defaultTitleFontSize = isMobile ? "H24" : "H20";
  const defaultTitleColor = isMobile ? "WHITE" : "BLUE900";
  const iconFill = theme.colors[titleIcon?.fill || "SLATE500"];
  const iconTheme = titleIcon?.theme || "filled";
  const iconSize = titleIcon?.size || 24;
  const showTitleIcon = titleIcon && titleIcon.type;
  return (
    <Container bgColor={bgColor} padding={padding} borderRadius={borderRadius}>
      <AccordionButton data-testid="accordion-button" onClick={onClick}>
        <TitleContainer>
          {showTitleIcon && (
            <Icon
              type={titleIcon.type}
              theme={iconTheme}
              fill={iconFill}
              size={iconSize}
            />
          )}

          <Text
            type={titleFontSize || defaultTitleFontSize}
            color={titleColor || defaultTitleColor}
          >
            {title}
          </Text>
        </TitleContainer>

        <StyledArrow
          type="Up"
          fill={theme.colors.SLATE500}
          size={24}
          open={isOpen}
        />
      </AccordionButton>
      <Content
        data-testid="accordion-content"
        maxHeight={isOpen ? `${content?.current?.scrollHeight}px` : "0px"}
        ref={content}
      >
        {children}
      </Content>
    </Container>
  );
};

export default Accordion;

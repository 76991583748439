import { NavigationShellProps } from "@components/NavigationShell/NavigationShell.component";

export const transformMenuSchema = (schema: NavigationShellProps["schema"]) => {
  return schema.map((item, i) => {
    if (item.subItems)
      return {
        id: `${i}`,
        title: item.title,
        subItems: item.subItems.map((subItem, j) => ({
          id: `${i}-${j}`,
          title: subItem.title,
        })),
      };
    return { id: i, title: item.title };
  });
};

import React, { FC, useState } from "react";
import { authSdk } from "../../utils/auth";
import { authBgColor } from "../Login/components/consts";
import { logException } from "@helpers/logException/logException";
import {
  LayoutShell,
  PromoTwo,
  PromoTwoBody,
  ForgotPassword as SharedForgotPassword,
} from "@ifgengineering/client-auth-components";
import RegulatoryBanner from "@components/RegulatoryBanner/RegulatoryBanner";
import { PublicRouteProps } from "../../pages/app";

const ForgotPassword: FC<PublicRouteProps> = () => {
  const [showFormSubmitted, setShowFormSubmitted] = useState(false);

  const handleSubmit = async (email: string) => {
    try {
      await authSdk.forgotPassword(email);
    } catch (e: any) {
      if (e.response.status !== 404) {
        logException({
          tag: "forgotPassword",
          scopeEmail: email,
          exception: e,
        });
      }
    }

    setShowFormSubmitted(true);
  };

  return (
    <LayoutShell
      Banner={() => <RegulatoryBanner />}
      Promo={() => <PromoTwo />}
      MobileFooter={() => <PromoTwoBody />}
      showHeader={true}
      showTrustBox={true}
      backgroundColor={authBgColor}
      isLayoutBEnabled={false}
    >
      <SharedForgotPassword
        contactURL="https://cur8.capital/contact-us"
        loginURL="/app/login"
        onSubmit={handleSubmit}
        showFormSubmitted={showFormSubmitted}
      />
    </LayoutShell>
  );
};

export default ForgotPassword;

import { RootState } from "../rootReducer";
import { TRemoteConfig } from "./remoteConfiguration.reducer";

export const remoteConfigurationSelector = (state: RootState): TRemoteConfig =>
  state.remoteConfig.data || {};

export const isLoadingRemoteConfigurationSelector = (
  state: RootState
): boolean => state.remoteConfig.loading;

export const neverLoadedRemoteConfigurationSelector = (
  state: RootState
): boolean => state.remoteConfig.neverLoaded;

export const isLiveChatEnabledSelector = (state: RootState): boolean =>
  state.remoteConfig.data.FF_LIVE_CHAT;

export const usdToGbpExchangeRate = (state: RootState): number =>
  state.remoteConfig.data.USD_GBP_EXCHANGE_RATE;

export const isIncomeDecisionEnabledSelector = (state: RootState): boolean =>
  state.remoteConfig.data.FF_INCOME_DECISION;

export const isLiquidationEnabledSelector = (state: RootState): boolean =>
  state.remoteConfig.data.FF_LIQUIDATION;

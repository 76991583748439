import { AuthUser, UserInfoOutput } from "@ifgengineering/client-auth-sdk";
import { SESSION_STATUSES } from "./types";

type checkResult = {
  status: SESSION_STATUSES | null;
  error: any | null;
  data?: AuthUser;
};

const checkSessionStatus = async (userInfo: () => Promise<UserInfoOutput>) => {
  const result: checkResult = { status: null, error: null };

  try {
    const getUserResult = await userInfo();

    if (getUserResult.state === "AUTHORIZED") {
      result.status = SESSION_STATUSES.VALID;
      result.data = getUserResult.user;
    }
  } catch (e: any) {
    result.error = e;
  }

  return result;
};

export default checkSessionStatus;

import { Entity } from "@ifgengineering/client-invest-sdk";
import { RootState } from "@state/rootReducer";

export const entitiesSelector = (state: RootState): Entity[] =>
  state.entity.data;

export const isLoadingEntitiesSelector = (state: RootState): boolean =>
  state.entity.loading;

export const neverLoadedEntitiesSelector = (state: RootState): boolean =>
  state.entity.neverLoaded;

export const entitySelector = (
  state: RootState,
  entityId: string
): Entity | undefined =>
  state.entity.data.find((entity) => entity.id === entityId);

import React from "react";
import { navigate } from "gatsby";
import PoweredByIFGLogo from "@assets/images/powered-by-ifg.png";
import { FilledButton, Text } from "@ifgengineering/component-library";
import {
  DisclaimerContainer,
  Image,
  LeftContainer,
  LeftContainerContentWrapper,
  PageContainer,
  RightContainer,
  RightContentContainer,
} from "./styled";
import useDynamicQuiz from "../../../hooks/useDynamicQuiz";
import getRemainingTime from "@components/blockedUserComponent/getRemainingTime";
import { CATBox } from "@components/blockedUserComponent/styled";
import Loading from "@components/Loading";

const OnboardingBlocked: React.FC = () => {
  const { blockedUntil, loading } = useDynamicQuiz(false);
  const disclaimer =
    "Cur8 Capital is a trading name of IFG.VC Limited, which is a subsidiary of Islamicfinanceguru Limited. IFG.VC Limited is authorised and regulated by the FCA (No. 943736). Nothing on this page should be construed as financial or tax advice. Remember, with investments your capital is at risk. Past performance is not necessarily an accurate indicator of future returns. © IFG.VC Limited. All rights reserved";

  if (loading) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <LeftContainer>
        <LeftContainerContentWrapper>
          <FilledButton
            testId="back-button"
            color="white"
            text="Back to live deals"
            textColor="SLATE800"
            icon="ArrowLeft"
            iconPosition="left"
            iconColor="SLATE800"
            width="fit-content"
            onClick={() => navigate("/app/deals")}
          />
          <Image src={PoweredByIFGLogo} alt="powered by IFG logo" />
        </LeftContainerContentWrapper>
      </LeftContainer>
      <RightContainer>
        <RightContentContainer>
          <Text type="H24" color="SLATE900" fontFamily="archiaregular">
            Quiz Failed
          </Text>
          <Text type="S16" color="SLATE800">
            Unfortunately you cannot sign up right now as you have incorrectly
            answered the quiz.
          </Text>
          {blockedUntil && (
            <Text type="H16" color="SLATE900">
              {getRemainingTime(blockedUntil)} remaining until you can
              re-attempt the quiz.
            </Text>
          )}

          <CATBox>
            <Text type="T24" color="WHITE" fontFamily="archiaregular">
              However, all is not lost
            </Text>
            <Text type="S16" color="WHITE">
              We offer a number of courses to help you on your investment
              journey
            </Text>
            <br />
            <FilledButton
              type="submit"
              text="View Courses"
              color="navy"
              height="48px"
              target="_blank"
              href="https://www.islamicfinanceguru.com/courses"
            />
          </CATBox>
        </RightContentContainer>
        <DisclaimerContainer>
          <Text type="P12" color="SLATE700">
            {disclaimer}
          </Text>
        </DisclaimerContainer>
      </RightContainer>
    </PageContainer>
  );
};

export default OnboardingBlocked;

import styled from "styled-components";
import { theme } from "@styles/theme";
import Icon from "@icon-park/react/es/all";

export const Content = styled.div<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: hidden;
  transition: max-height 0.6s ease;
`;

export const Container = styled.div<{
  bgColor?: string;
  padding?: number;
  borderRadius?: number;
}>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  padding: ${({ padding }) => padding || 0}px;
  border-radius: ${({ borderRadius }) => borderRadius || 0}px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AccordionButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledArrow = styled(Icon)<{ open?: boolean }>`
  transform: ${({ open }) => !open && "rotate(180deg)"};
  fill: ${theme.colors.SLATE400};
  transition: 0.3s ease;
`;

import React, { FC, useEffect, useState } from "react";
import Layout from "../Layout";
import {
  Card,
  MEDIA_QUERIES,
  OutlinedButton,
  Text,
  Stack,
  FilledButton,
  theme,
} from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import { navigate } from "gatsby";
import Icon from "@icon-park/react/es/all";
import ModalWindow from "@components/ModalWindow";
import createYotiSession, { YotiInfo } from "../../helpers/helper";
import { logException } from "@helpers/logException/logException";
import useAnalytics from "@helpers/useAnalytics";
import { useSelector } from "react-redux";
import { investorSelector } from "@state/investor/investor.selectors";

const StartVerification: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [yotiInfo, setYotiInfo] = useState<YotiInfo | null>(null);
  const isTabPort = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const investor = useSelector(investorSelector);
  const fireAnaytics = useAnalytics("kycYotiCheck");

  const handleBackClick = () => {
    navigate("/app/identity/default");
  };

  const firePageViewAnalytics = useAnalytics("pageView");

  const firePageView = async (eventName: string) => {
    (await firePageViewAnalytics)({ eventName });
  };

  const handleVerifyNowClick = async () => {
    try {
      setLoading(true);
      const res = await createYotiSession();
      if (res) {
        setYotiInfo(res);
        setShowModal(true);
        setLoading(false);
        (await fireAnaytics)({ email: investor?.email, status: "ATTEMPTED" });
      }
    } catch (e: any) {
      setLoading(false);
      if (e.response.status === 422) {
        navigate("/app/deals");
        return;
      }

      (await fireAnaytics)({ email: investor?.email, status: "FAILED" });
      logException({
        exception: e,
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/app/identity/default/end");
  };

  useEffect(() => {
    firePageView("cur8:identity_pageViewed");
  }, []);

  return (
    <Layout>
      <ModalWindow
        show={showModal}
        onClose={handleCloseModal}
        width={isTabPort ? "100%" : "1000px"}
        height="90vh"
      >
        <iframe
          width={isTabPort ? "100%" : "950"}
          height="100%"
          src={yotiInfo?.url}
          allow="camera"
        ></iframe>
      </ModalWindow>
      <Stack
        gap={95}
        orientation={isTabPort ? "vertical" : "horizontal"}
        alignItems="flex-start"
      >
        <Stack
          gap={16}
          orientation="vertical"
          {...(isTabPort ? {} : { minWidth: 500 })}
        >
          <Text type="H24" color="SLATE900" fontFamily="archiabold">
            Identity
          </Text>
          <Text type="P16" color="SLATE800">
            As per FCA guidelines, we need to verify your identity before being
            able to invest.
          </Text>
          <Text type="P18" color="SLATE800">
            Required Documents
          </Text>
          <Card elevation={0}>
            <Text type="H14" color="SLATE800">
              Proof of Address
            </Text>
            <Text type="P14" color="SLATE800">
              Upload{" "}
              <Text type="S14" color="SLATE800">
                1 document
              </Text>{" "}
              as proof of address
            </Text>
            <br />
            <Text type="P14" color="SLATE800">
              Requirements
              <ul>
                <li>Must be dated in the last 3 months</li>
                <li>Cannot be a mobile or cable TV bill</li>
                <li>Cannot be a digital copy (i.e. a photo of a screen)</li>
                <li>Can be a PDF or photo of a hard copy</li>
                <li>Must display name, address and the date.</li>
              </ul>
            </Text>
          </Card>
          <Card elevation={0}>
            <Text type="H14" color="SLATE800">
              Identity Document
            </Text>
            <Text type="P14" color="SLATE800">
              Upload 1 document as proof of identity e.g a passport
            </Text>
          </Card>
          <Stack gap={16}>
            <OutlinedButton
              width={isTabPort ? "100%" : "fit-content"}
              text="Back"
              onClick={handleBackClick}
            />
            <FilledButton
              width={isTabPort ? "100%" : "fit-content"}
              color="blue"
              text="Verify Identity Now"
              onClick={handleVerifyNowClick}
              isLoading={loading}
              disabled={loading}
            />
          </Stack>
        </Stack>
        <Stack>
          <Card elevation={0} background="SLATE100" width="300px">
            <Stack alignItems="center" gap={4}>
              <Icon type="Shield" fill={theme.colors.GREEN600} size="15" />
              <Text type="U14" color="GREEN600" fontFamily="archiasemibold">
                Secure
              </Text>
            </Stack>
            <Text type="P14" color="SLATE800">
              Your personal information is transmitted over a secure encrypted
              connection. It is never used for any purpose other than required
              by regulations.
            </Text>
          </Card>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default StartVerification;

import { AxiosError, AxiosPromise } from "axios";
import { SESSION_STATUSES } from "./types";

const check2FA = async (
  refreshAccessToken: () => Promise<AxiosPromise>,
  error: AxiosError
) => {
  if (error?.response?.status === 401) {
    try {
      const getRefreshTokenResult = await refreshAccessToken();

      if (getRefreshTokenResult.status === 201) {
        return SESSION_STATUSES.VALID;
      }

      if (getRefreshTokenResult.status === 401) {
        return SESSION_STATUSES.INVALID;
      }
    } catch (e) {
      return SESSION_STATUSES.INVALID;
    }
  }

  if (error?.response?.status === 403) {
    return SESSION_STATUSES.REQUIRE_2FA;
  }

  return SESSION_STATUSES.INVALID;
};

export default check2FA;

import React, { useEffect } from "react";
import {
  LayoutShell,
  SignupApple as AppleSignup,
  PromoTwo,
  PromoTwoBody,
  useClientAuthComponents,
} from "@ifgengineering/client-auth-components";
import RegulatoryBanner from "@components/RegulatoryBanner/RegulatoryBanner";
import { authBgColor } from "@components/Login/components/consts";
import { navigate } from "gatsby";
import { signupAppleAsync } from "@state/auth/auth.actions";
import { useDispatch } from "react-redux";
import { PublicRouteProps } from "../../../pages/app";

const SignupApple: React.FC<PublicRouteProps> = () => {
  const { appleAuthData } = useClientAuthComponents();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appleAuthData?.isPrivateEmail) {
      navigate("/app/login");
    }
  }, []);

  const handleRegister = () => {
    if (appleAuthData?.authorizationCode) {
      const { authorizationCode } = appleAuthData;
      dispatch(signupAppleAsync({ authorizationCode }));
    }
  };

  return (
    <LayoutShell
      Banner={() => <RegulatoryBanner />}
      Promo={() => <PromoTwo />}
      MobileFooter={() => <PromoTwoBody />}
      showHeader={true}
      showTrustBox={true}
      backgroundColor={authBgColor}
      isLayoutBEnabled={false}
    >
      <AppleSignup
        loginUrl="/app/login"
        signupUrl="/app/signup"
        onRegister={handleRegister}
      />
    </LayoutShell>
  );
};

export default SignupApple;

import { AuthUser, UserInfoOutput } from "@ifgengineering/client-auth-sdk";
import { AxiosPromise } from "axios";
import React, { FC, useState, useEffect } from "react";
import { checkSession } from "./checkSession";
import { SESSION_STATUSES } from "./types";

type Props = {
  children: React.ReactNode;
  failureCallback: () => void;
  loadingComponent?: JSX.Element;
  refreshAccessToken: () => Promise<AxiosPromise>;
  twoFactorCallback?: () => void;
  userInfo: () => Promise<UserInfoOutput>;
  userInfoSuccessCallback: (user: AuthUser) => void;
  refreshATSuccessCallback?: () => void;
  // Instead of validating the session first, then render the child component
  // it promptly renders the child component but validates the session asynchronously
  // make apps that don't require constant API request looks faster to the user
  validateInBackground?: boolean;
  // stop triggering user-info to check if the session is alive.
  ignore?: boolean;
  location?: boolean;
  isAuthenticated?: boolean;
  validateOnLocationChange?: boolean;
};

const SecurityLayer: FC<Props> = ({
  children,
  failureCallback,
  loadingComponent,
  refreshAccessToken,
  twoFactorCallback,
  userInfo,
  userInfoSuccessCallback,
  refreshATSuccessCallback,
  validateInBackground = false,
  ignore = false,
  location,
  isAuthenticated,
  validateOnLocationChange,
}) => {
  const mountedRef = React.useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sessionStatus, setSessionStatus] = useState<SESSION_STATUSES | null>(
    null
  );
  const [userInfoData, setUserInfoData] = useState<AuthUser>();

  const loading = loadingComponent ? loadingComponent : <div>Loading...</div>;

  useEffect(() => {
    // on first mount ignore this listener - we only want this check if when the user navigates to a new page
    if (mountedRef.current) {
      if (validateOnLocationChange && !isAuthenticated && !ignore) {
        setSessionStatus(SESSION_STATUSES.INVALID);
        failureCallback();
      }
    } else {
      mountedRef.current = true;
    }
  }, [location]);

  useEffect(() => {
    const check = async () => {
      setIsLoading(true);
      try {
        const { status, data } = await checkSession(
          refreshAccessToken,
          userInfo
        );

        setSessionStatus(status);

        if (data) {
          setUserInfoData(data);
        }

        setIsLoading(false);
      } catch (e) {
        setSessionStatus(SESSION_STATUSES.INVALID);
        failureCallback();
        setIsLoading(false);
      }
    };

    if (!ignore) {
      check();
    }
  }, [ignore]);

  useEffect(() => {
    if (sessionStatus === null) {
      return;
    }

    if (
      sessionStatus === SESSION_STATUSES.VALID &&
      !userInfoData &&
      refreshATSuccessCallback
    ) {
      refreshATSuccessCallback();
    }

    if (sessionStatus === SESSION_STATUSES.VALID && userInfoData) {
      userInfoSuccessCallback(userInfoData);
    }

    if (sessionStatus === SESSION_STATUSES.INVALID) {
      failureCallback();
    }

    if (sessionStatus === SESSION_STATUSES.REQUIRE_2FA && twoFactorCallback) {
      twoFactorCallback();
    }
  }, [sessionStatus, userInfoData]);

  if (!validateInBackground && isLoading) {
    return loading;
  }

  if (validateInBackground) {
    return <span data-testid="security-layer">{children}</span>;
  }

  return (
    <span data-testid="security-layer">
      {sessionStatus === SESSION_STATUSES.VALID ? children : null}
    </span>
  );
};

export default SecurityLayer;

import React, { FC, useEffect } from "react";
import Layout from "../Layout";
import {
  Card,
  MEDIA_QUERIES,
  OutlinedButton,
  Text,
  Stack,
  FilledButton,
  theme,
} from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import { navigate } from "gatsby";
import Icon from "@icon-park/react/es/all";
import useAnalytics from "@helpers/useAnalytics";

const StartVerification: FC = () => {
  const isTabPort = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const handleBackClick = () => {
    navigate("/app/identity/extra-documents-required");
  };
  const handleStart = () => {
    navigate("/app/identity/extra-documents-required/upload");
  };

  const firePageViewAnalytics = useAnalytics("pageView");

  const firePageView = async (eventName: string) => {
    (await firePageViewAnalytics)({ eventName });
  };

  useEffect(() => {
    firePageView("cur8:identity_pageViewed");
  }, []);

  return (
    <Layout>
      <Stack
        gap={95}
        orientation={isTabPort ? "vertical" : "horizontal"}
        alignItems="flex-start"
      >
        <Stack
          gap={16}
          orientation="vertical"
          {...(isTabPort ? {} : { minWidth: 600 })}
        >
          <Text type="H24" color="SLATE900" fontFamily="archiabold">
            Identity
          </Text>
          <Text type="P16" color="SLATE800">
            As per FCA guidelines, we need to verify your identity before being
            able to invest.
          </Text>
          <Text type="P18" color="SLATE800">
            Required Documents
          </Text>
          <Card elevation={0}>
            <Text type="H14" color="SLATE800">
              Source of Funds
            </Text>
            <Text type="P14" color="SLATE800">
              Upload 1 document as proof of source of funds
            </Text>
          </Card>
          <Card elevation={0}>
            <Text type="H14" color="SLATE800">
              Proof of Address
            </Text>
            <Text type="P14" color="SLATE800">
              Upload{" "}
              <Text type="S14" color="SLATE800">
                2 document
              </Text>{" "}
              as proof of address
            </Text>
            <br />
            <Text type="P14" color="SLATE800">
              Requirements
              <ul>
                <li>Must be dated in the last 3 months</li>
                <li>Cannot be a mobile or cable TV bill</li>
                <li>
                  2 Documents shouldn’t be of the same type (e.g. no 2 phone
                  bills)
                </li>
                <li>Cannot be a digital copy (i.e. a photo of a screen)</li>
                <li>Can be a PDF or photo of a hard copy</li>
                <li>Must display name, address and the date.</li>
              </ul>
            </Text>
          </Card>
          <Card elevation={0}>
            <Text type="H14" color="SLATE800">
              Identity Document
            </Text>
            <Text type="P14" color="SLATE800">
              Upload 1 document as proof of identity e.g a passport
            </Text>
          </Card>
          <Stack gap={16}>
            <OutlinedButton
              width={isTabPort ? "100%" : "fit-content"}
              text="Back"
              onClick={handleBackClick}
            />
            <FilledButton
              width={isTabPort ? "100%" : "fit-content"}
              color="blue"
              text="Start Identity Verification"
              onClick={handleStart}
            />
          </Stack>
        </Stack>
        <Stack>
          <Card elevation={0} background="SLATE100" width="300px">
            <Stack alignItems="center" gap={4}>
              <Icon type="Shield" fill={theme.colors.GREEN600} size="15" />
              <Text type="U14" color="GREEN600" fontFamily="archiasemibold">
                Secure
              </Text>
            </Stack>
            <Text type="P14" color="SLATE800">
              Your personal information is transmitted over a secure encrypted
              connection. It is never used for any purpose other than required
              by regulations.
            </Text>
          </Card>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default StartVerification;

import styled from "styled-components";
import { theme } from "@styles/theme";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100vh;
  width: 100%;
`;

export const FormsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 80px;

  @media ${MEDIA_QUERIES.TAB_LAND} {
    padding: 67px;
  }
`;

export const Cur8Logo = styled.img`
  height: 24px;
  object-fit: contain;
`;

export const PoweredByLogo = styled.img`
  display: flex;
  justify-content: flex-start;
  height: 33px;
  object-fit: contain;
  margin-top: 64px;
  width: fit-content;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.colors["SLATE200"]};
  flex-grow: 1;
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DisclaimerContainer = styled.div`
  width: 100%;
  padding: 24px 104px;
  background-color: ${theme.colors["SLATE100"]};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  z-index: 10;
  background-color: ${theme.colors["SLATE900"]};
`;

export const LeftContainerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 48px;
  height: 100%;
  max-width: 327px;
  width: 100%;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const FlowContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

import React, { FC, useEffect, useState } from "react";
import Layout from "../Layout";
import {
  Card,
  MEDIA_QUERIES,
  OutlinedButton,
  Text,
  Stack,
  FilledButton,
  theme,
  Input,
} from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import { navigate } from "gatsby";
import Icon from "@icon-park/react/es/all";
import ModalWindow from "@components/ModalWindow";
import { useDispatch, useSelector } from "react-redux";
import createYotiSession, { YotiInfo } from "../../helpers/helper";
import UploadDocuments from "@components/UploadDocuments/UploadDocuments";
import { logException } from "@helpers/logException/logException";
import { investorSelector } from "@state/investor/investor.selectors";
import { clientInvestSdk } from "@utils/clientInvestSdk";
import { Upload } from "@ifgengineering/client-invest-sdk";
import { getInvestorAPI } from "@state/investor/investor.actions";
import useAnalytics from "@helpers/useAnalytics";

const UploadFiles: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [yotiInfo, setYotiInfo] = useState<YotiInfo | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState<Upload[]>([]);
  const isTabPort = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const investor = useSelector(investorSelector);
  const fireAnaytics = useAnalytics("kycYotiCheck");
  const dispatch = useDispatch();

  const firePageViewAnalytics = useAnalytics("pageView");

  const firePageView = async (eventName: string) => {
    (await firePageViewAnalytics)({ eventName });
  };

  const handleBackClick = () => {
    navigate("/app/identity/extra-documents-required/start");
  };

  const handleVerifyNowClick = async () => {
    if (!files.length) {
      setErrorMessage("Files are required");
      return;
    }

    try {
      setLoading(true);
      setErrorMessage("");
      await clientInvestSdk.postSourceOfFundDescription(description);
      await dispatch(getInvestorAPI());
      const res = await createYotiSession();

      if (res) {
        setYotiInfo(res);
        setShowModal(true);
        setLoading(false);
        (await fireAnaytics)({ email: investor?.email, status: "ATTEMPTED" });
      }
    } catch (e: any) {
      setLoading(false);
      if (e.response.status === 422) {
        navigate("/app/deals");
        return;
      }

      (await fireAnaytics)({ email: investor?.email, status: "FAILED" });
      logException({
        exception: e,
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/app/identity/extra-documents-required/end");
  };

  const onUpload = async (file: File) => {
    if (investor?.id) {
      try {
        const uploadedFile = await clientInvestSdk.uploadKYCDocument(file);
        await dispatch(getInvestorAPI());
        setFiles([...files, uploadedFile.data]);
        setErrorMessage("");
      } catch (e: any) {
        setErrorMessage(e.response.data.message);
      }
    }
  };

  const onDelete = async (fileId: string) => {
    if (investor?.id) {
      await clientInvestSdk.deleteKYCDocument(fileId);
      await dispatch(getInvestorAPI());
      setFiles(files.filter((file) => file.id !== fileId));
    }
  };

  useEffect(() => {
    if (investor?.kycProfile) {
      setFiles(investor?.kycProfile.sourceOfFundFiles);
    }
  }, [investor?.kycProfile?.sourceOfFundFiles.length]);

  useEffect(() => {
    if (investor?.kycProfile?.sourceOfFundDescription) {
      setDescription(investor.kycProfile.sourceOfFundDescription);
    }
  }, [investor?.kycProfile?.sourceOfFundDescription]);

  useEffect(() => {
    firePageView("cur8:sourceOfFunds_pageViewed");
  }, []);

  return (
    <Layout>
      <ModalWindow
        show={showModal}
        onClose={handleCloseModal}
        width={isTabPort ? "100%" : "1000px"}
        height="90vh"
      >
        <iframe
          width={isTabPort ? "100%" : "950"}
          height="100%"
          src={yotiInfo?.url}
          allow="camera"
        ></iframe>
      </ModalWindow>
      <Stack
        gap={95}
        orientation={isTabPort ? "vertical" : "horizontal"}
        alignItems="flex-start"
      >
        <Stack
          gap={16}
          orientation="vertical"
          {...(isTabPort ? {} : { minWidth: 600 })}
        >
          <Text type="H24" color="SLATE900" fontFamily="archiabold">
            Identity
          </Text>
          <Text type="P16" color="SLATE800">
            As per FCA guidelines, we need to verify your identity before being
            able to invest.
          </Text>
          <Card elevation={0}>
            <Stack orientation="vertical" gap={16}>
              <div>
                <Text type="H16" color="SLATE800">
                  Source of Funds
                </Text>
                <Text type="P14" color="SLATE800">
                  Upload at least one document to prove source of funds e.g a
                  wage slip, record of inheritance or a bank statement.
                </Text>
              </div>
              <div>
                <UploadDocuments
                  title=""
                  subTitle=""
                  documentType="identityDocs"
                  documents={files}
                  onUpload={onUpload}
                  onDelete={onDelete}
                />
                <Stack orientation="vertical">
                  <Text type="P14" color="ERROR800">
                    {errorMessage}
                  </Text>
                </Stack>
              </div>
              <div>
                <Text type="H14" color="SLATE900" fontFamily="archiabold">
                  Description
                </Text>
                <Text type="P14" color="SLATE800">
                  Briefly describe your source of funds e.g. salary or
                  inheritance etc
                </Text>
              </div>
              <Input
                isLong
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                height="200px"
              />
            </Stack>
          </Card>
          <Stack gap={16}>
            <OutlinedButton
              width={isTabPort ? "100%" : "fit-content"}
              text="Back"
              onClick={handleBackClick}
            />
            <FilledButton
              width={isTabPort ? "100%" : "fit-content"}
              color="blue"
              text="Proceed"
              onClick={handleVerifyNowClick}
              isLoading={loading}
              disabled={loading}
            />
          </Stack>
        </Stack>
        <Stack>
          <Card elevation={0} background="SLATE100" width="300px">
            <Stack alignItems="center" gap={4}>
              <Icon type="Shield" fill={theme.colors.GREEN600} size="15" />
              <Text type="U14" color="GREEN600" fontFamily="archiasemibold">
                Secure
              </Text>
            </Stack>
            <Text type="P14" color="SLATE800">
              Your personal information is transmitted over a secure encrypted
              connection. It is never used for any purpose other than required
              by regulations.
            </Text>
          </Card>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default UploadFiles;

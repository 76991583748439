import { RootState } from "../rootReducer";
import { AuthState } from "./auth.reducer";

export const authSelector = (state: RootState): AuthState => state.auth;
export const isAuthenticatedSelector = (state: RootState): boolean =>
  state.auth.isAuthenticated;
export const authLoadingSelector = (state: RootState): boolean =>
  state.auth.loading;

export const neverLoadedAuthSelector = (state: RootState): boolean =>
  state.auth.neverLoaded;

import styled from "styled-components";
import { ColorType, theme } from "@styles/theme";
import { IFlowNavMobileColors } from "@components/FlowNav/types";

export const FlowNavContainer = styled.nav<{ background: ColorType }>`
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: ${({ background }) => theme.colors[background]};
`;

export const FlowNavItemContainer = styled.div`
  display: flex;
  padding-left: 32px;
  position: relative;
`;
export const FlowSubNavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 24px;
  padding-top: 18px;
`;

export const MobileNavItem = styled.button<{
  isSelected: boolean;
  disabledConnectedFlow?: boolean;
  colors: IFlowNavMobileColors;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  height: 40px;
  width: fit-content;
  padding: 10px 20px;
  margin-right: ${({ disabledConnectedFlow }) =>
    disabledConnectedFlow ? "30px" : "0"};
  gap: 11px;
  border-radius: 3px;
  border: ${({ isSelected, colors }) =>
    `1px solid ${
      isSelected
        ? theme.colors[colors.selectedMobileBorder]
        : theme.colors[colors.mobileBorder]
    }`};
  background-color: ${({ isSelected, colors }) =>
    isSelected
      ? theme.colors[colors.selectedMobileBackground]
      : theme.colors[colors.mobileBackground]};
  color: ${theme.colors.SLATE400};
`;

export const MobileNavContainer = styled.div<{
  disabledConnectedFlow?: boolean;
  colors: IFlowNavMobileColors;
  padding?: string;
}>`
  display: flex;
  width: 100vw;
  flex-direction: row;
  background-color: ${({ colors }) =>
    (colors.containerBackground && theme.colors[colors.containerBackground]) ||
    "noneZ"};
  overflow-y: scroll;
  padding: ${({ padding, disabledConnectedFlow }) =>
    padding || (disabledConnectedFlow ? "10px 20px" : "0 24px")};
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MobileNavItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: fit-content;
  justify-content: center;
  align-items: center;
`;

export const MobileNavConnector = styled.div`
  border-top: 1px solid ${theme.colors.SLATE700};
  width: 20px;
`;

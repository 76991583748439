import React, { FC } from "react";
import { authSdk } from "../../utils/auth";
import { authBgColor } from "../Login/components/consts";
import {
  LayoutShell,
  PromoTwo,
  PromoTwoBody,
  VerifyEmail,
} from "@ifgengineering/client-auth-components";
import RegulatoryBanner from "@components/RegulatoryBanner/RegulatoryBanner";
import { PublicRouteProps } from "../../pages/app";

export const LoginContainer: FC<PublicRouteProps> = () => {
  return (
    <LayoutShell
      Banner={() => <RegulatoryBanner />}
      Promo={() => <PromoTwo />}
      MobileFooter={() => <PromoTwoBody />}
      showHeader={true}
      showTrustBox={true}
      backgroundColor={authBgColor}
      isLayoutBEnabled={false}
    >
      <VerifyEmail
        verifyEmailCallback={authSdk.verifyEmail}
        loginURL="/app/login"
      />
    </LayoutShell>
  );
};

export default LoginContainer;

import React, { FC, useEffect } from "react";
import Layout from "../Layout";
import {
  Card,
  MEDIA_QUERIES,
  OutlinedButton,
  Text,
  theme,
  Stack,
  FilledButton,
} from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import Icon from "@icon-park/react/es/all";
import { navigate } from "gatsby";
import styled from "styled-components";
import useAnalytics from "@helpers/useAnalytics";

const PageWrap = styled.div`
  max-width: 700px;
`;

const DefaultFlow: FC = () => {
  const isTabPort = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const handleSkipClick = () => {
    navigate("/app/deals");
  };
  const handleVerifyNowClick = () => {
    navigate("/app/identity/default/start");
  };

  const firePageViewAnalytics = useAnalytics("pageView");

  const firePageView = async (eventName: string) => {
    (await firePageViewAnalytics)({ eventName });
  };

  useEffect(() => {
    firePageView("cur8:identity_pageViewed");
  }, []);

  return (
    <Layout>
      <PageWrap>
        <Stack
          gap={16}
          orientation="vertical"
          {...(isTabPort ? {} : { width: 500 })}
        >
          <Text type="H24" color="SLATE900" fontFamily="archiabold">
            Congratulations, you are now able to invest.
          </Text>
          <Text type="S16" color="SLATE800">
            The final step is completing our identity checks, which will require
            the following documents:
          </Text>
          <Card elevation={0}>
            <Stack gap={16} orientation="vertical">
              <Stack gap={5} alignItems="center">
                <Icon
                  type="FileSuccess"
                  fill={theme.colors.BLUE700}
                  size={24}
                />
                <Text type="S16" color="SLATE800">
                  Proof of Address
                </Text>
                <Text type="P16" color="SLATE800">
                  (e.g. Utility Bill)
                </Text>
              </Stack>
              <Stack gap={5} alignItems="center">
                <Icon
                  type="FileSuccess"
                  fill={theme.colors.BLUE700}
                  size={24}
                />
                <Text type="S16" color="SLATE800">
                  Proof of Identity
                </Text>
                <Text type="P16" color="SLATE800">
                  (e.g. ID or Passport)
                </Text>
              </Stack>
            </Stack>
          </Card>
          <Text type="S16" color="SLATE800">
            You can choose to do this at a later stage, but verification is
            required in order to transfer any funds and could impact your
            ability to meet deal deadlines (verification is often immediate, but
            can sometimes take up to 48 hours).
          </Text>
          <Stack gap={16}>
            <OutlinedButton
              width={isTabPort ? "100%" : "fit-content"}
              text="I’ll Verify Later"
              onClick={handleSkipClick}
              testId="verify-later-button"
            />
            <FilledButton
              width={isTabPort ? "100%" : "fit-content"}
              color="blue"
              text="Verify Identity Now"
              onClick={handleVerifyNowClick}
            />
          </Stack>
        </Stack>
      </PageWrap>
    </Layout>
  );
};

export default DefaultFlow;

import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

interface DecodedAppleIdToken {
  email?: string;
  sub?: string;
}

const getAndStoreAppleIdToken = (
  appleIdToken?: string
): { token: string | null } => {
  if (!appleIdToken) {
    return {
      token: null,
    };
  }
  const decodedToken = jwtDecode<DecodedAppleIdToken>(appleIdToken || "");
  const email = decodedToken?.email;
  const sub = decodedToken?.sub;

  let idToken: string | undefined | null;

  if (localStorage.getItem("apple_id_token")) {
    idToken = localStorage.getItem("apple_id_token");
  }

  if (email && sub) {
    localStorage.setItem("apple_id_token", appleIdToken);
  }

  const currentToken = email && appleIdToken;
  const tokenFromStore = idToken;
  const token = currentToken ?? tokenFromStore ?? null;

  if (!token) {
    toast.error(
      "Something wrong. Try again later or login via a different method."
    );
  }

  return {
    token,
  };
};

export default getAndStoreAppleIdToken;

import { theme } from "@ifgengineering/component-library";
import styled from "styled-components";

export const VerifyEntityContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  gap: 15px;
`;

export const SecurityBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${theme.colors.GREEN600};
`;

export const UploadDocumentsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const DropArea = styled.div`
  border: 1px dashed ${theme.colors.SLATE400};
  background: ${theme.colors.SLATE100};
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 5px;
  position: relative;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  gap: 18px;
`;

export const UploadedFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const UploadedFile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & p {
    max-width: 550px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & svg {
    cursor: pointer;
  }
`;

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector } from "@state/auth/auth.selectors";
import { investorSelector } from "@state/investor/investor.selectors";
import { isLiveChatEnabledSelector } from "@state/remoteConfiguration/remoteConfiguration.selectors";
import { format } from "date-fns";

const useIntercom = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const investor = useSelector(investorSelector);
  const liveChatEnabled = useSelector(isLiveChatEnabledSelector);

  useEffect(() => {
    if (liveChatEnabled && isAuthenticated) {
      const scriptId = "intercom-script";
      let script = document.getElementById(scriptId) as HTMLScriptElement;

      if (!script) {
        script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://widget.intercom.io/widget/x9lzu6ui";
        script.async = true;
        script.onload = () => {
          window.Intercom("boot", {
            app_id: "x9lzu6ui",
          });
        };
        document.head.appendChild(script);
      }

      return () => {
        const existingScript = document.getElementById(scriptId);
        if (existingScript) {
          document.head.removeChild(existingScript);
        }
      };
    }
  }, [liveChatEnabled, isAuthenticated]);

  useEffect(() => {
    if (window.Intercom && liveChatEnabled) {
      if (isAuthenticated && investor?.email) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "x9lzu6ui",
          name: investor?.firstName || null,
          first_name: investor?.firstName,
          last_name: investor?.lastName,
          email: investor?.email,
          onboarding_status: investor.onboardingStatus,
          member: investor.isMember,
          kyc_status: investor.kycProfile?.status,
          user_since:
            investor?.created_at &&
            format(new Date(investor?.created_at), "dd-MM-yyyy"),
          // user_hash: investor?.intercomEmailHash, todo - confirm once correctly configured on API
        });
      } else {
        window.Intercom("shutdown");
      }
    }
  }, [isAuthenticated, investor]);
};

export default useIntercom;

import { RootState } from "../rootReducer";

export const bankAccountsSelector = (state: RootState) =>
  state.bankAccount.data;

export const neverLoadedBankAccountsSelector = (state: RootState): boolean =>
  state.bankAccount.neverLoaded;

export const isLoadingBankAccountsSelector = (state: RootState): boolean =>
  state.bankAccount.loading;
